@charset "UTF-8";

@import "definitions";

/* chat_widget
====================================================================================================================================== */
#chat_widget{
  position: fixed;
  bottom: 0;
  right: 15px;
  z-index: 2147483647;
  width: 390px;
  background-color: #fff;
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  font-size: 0.88em;
  line-height: 1.8;
  transition: .4s ease-out;
  translate: 0 100%;
  &.open{
    translate: 0;
    box-shadow: 0 0 99px rgba(0,0,0,0.35);
  }
  &.minimize{
    translate: 0 calc(100% - 65px);
  }
  &.maximize{
    #chat_widget_body{
      height: calc(100vh - 65px - 5px);
    }
  }
  p, ul{
    margin: 0;
    padding: 0;
  }
  ul{
    list-style-type: none;
  }
}

.cms-ready{
  #chat_widget{
    &.maximize{
      #chat_widget_body{
        height: calc(100vh - 46px - 65px - 5px);
      }
    }
  }
}

#chat_widget_header{
  background-color: #fff;
  color: $origin_color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding: 0 20px;
  .widget_title{
    text-align: left;
    line-height: 1.3;
  }
  .chat_buttons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 18px;
    .button_wrap{
      cursor: pointer;
      width: 28px;
      transition: .2s;
      img{
        width: 100%;
      }
    }
  }
}

@media (hover: hover) {
  #chat_widget_header{
    .chat_buttons{
      .button_wrap:hover{
        opacity: 0.5;
      }
    }
  }
}

#first_select_menu{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  border-top: 1px solid #d9d9d9;
  background-color: #fff;
  transition: .5s;
  &.closed{
    translate: 0 100%;
    .menu_open_close{
      a{
        background-image: url(../images/icon/chat_menu_open.svg);
      }
    }
  }
  .menu_open_close{
    position: absolute;
    bottom: 100%;
    left: 0;
    a{
      display: block;
      text-decoration: none;
      font-size: 0.8em;
      font-weight: normal;
      line-height: 1;
      color: #fff;
      padding: 12px 20px 12px 38px;
      border-top-right-radius: 8px;
      background: $blue url(../images/icon/chat_menu_close.svg) no-repeat left 12px center;
      background-size: 20px auto;
    }
  }
  ul{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    li{
      border: {
        right: 1px solid #d9d9d9;
        bottom: 1px solid #d9d9d9;
      }
      &:nth-child(even){
        border-right: none;
      }
      a{
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        color: $blue;
        padding: 10px;
      }
      .icon{
        width: 46px;
        img{
          width: 100%;
        }
      }
      .text_area{
        width: calc(100% - 46px - 10px);
        font-weight: bold;
        line-height: 1.5;
        p{
          margin-bottom: 0;
        }
        .small_text{
          font-size: 0.6em;
        }
      }
    }
  }
}

@media (hover: hover) {
  #first_select_menu{
    .menu_open_close{
      a:hover{
        opacity: 0.7;
      }
    }
    ul{
      li{
        a:hover{
          opacity: 0.7;
        }
      }
    }
  }
}

#chat_widget_body{
  background-color: #fff;
  padding: 0 20px;
  height: 485px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: .4s ease-out;
  .chat_body_inner{
    padding-bottom: 90px;
  }
  .chat_log{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
    &:last-of-type{
      margin-bottom: 0;
    }
    .text_area{
      width: calc(100% - 44px - 11px);
    }
    .fukidashi{
      border-radius: 10px;
      position: relative;
      padding: 13px 17px;
      background-color: #f0f0f0;
      &:before {
        content: "";
        position: absolute;
        display: block;
        right: 100%;
        top: calc(22px - 5px);
        width: calc(tan(60deg) * 9px / 2);
        height: 9px;
        clip-path: polygon(100% 0, 100% 100%, 0 50%);
        background-color: #f0f0f0;
      }
      p{
        margin-bottom: 0;
      }
    }
    .choices_list{
      margin-top: 0.9em;
      li{
        font-weight: bold;
        background-color: #fff;
        border-radius: 6px;
        padding: 9px 13px;
        margin-bottom: 5px;
        color: $link_color;
        cursor: pointer;
        &:last-child{
          margin-bottom: 0;
        }
      }
      &.link_list{
        li{
          padding: 0;
          a{
            display: block;
            padding: 9px 13px;
            text-decoration: none;
            &[target="_blank"]{
              padding-right: 27px;
              background: url(../images/icon/outlink_blue.svg) no-repeat right 14px center;
              background-size: 12px auto;
            }
          }
        }
      }
    }
    .confirm_area{
      margin-top: 16px;
      font-size: 1.02em;
      p{
        margin-bottom: 5px;
      }
    }
    .alternative_list{
      display: flex;
      justify-content: flex-start;
      gap: 12px;
      li{
        font-weight: bold;
        color: $blue;
        border-radius: 8px;
        padding: 9px 12px;
        cursor: pointer;
        background-color: #fff;
        border: 1px solid $blue;
        &.bg_blue{
          color: #fff;
          background-color: $blue;
        }
      }
      &.vertical{
        display: block;
        max-width: 12em;
        li{
          + li{
            margin: 6px 0 0;
          }
        }
      }
    }
    &.talk_bot{
      .icon{
        width: 44px;
        aspect-ratio: 1;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .stamp{
        width: unquote('min(200px, 100%)');
        img{
          width: 100%;
        }
      }
    }
    &.talk_user{
      justify-content: flex-end;
      .text_area{
        width: fit-content;
      }
      .fukidashi{
        background-color: $blue;
        color: #fff;
        font-weight: bold;
        &:before {
          right: auto;
          left: calc(100% - 1px);
          top: 50%;
          translate: 0 -50%;
          clip-path: polygon(0 0, 100% 50%, 0 100%);
          background-color: $blue;
        }
      }
    }
  }
}

@media (hover: hover) {
  #chat_widget_body{
    .chat_log{
      .choices_list{
        li:hover{
          opacity: 0.7;
        }
      }
      .alternative_list{
        li{
          &:hover{
            color: #fff;
            background-color: $blue;
          }
          &.bg_blue{
            &:hover{
              color: $blue;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}


/* button_chat_open
====================================================================================================================================== */
#button_chat_open{
  position: fixed;
  bottom: 15px;
  right: 90px;
  z-index: 99999;
  a{
    display: block;
    background-color: $blue;
    border-radius: 50px;
    font-size: 0.9em;
    font-weight: bold;
    text-align: left;
    line-height: 1.4;
    padding: 11px 96px 11px 28px;
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
    text-decoration: none;
    color: #fff;
    position: relative;
    transition: .2s;
    .charactor{
      display: block;
      width: 96px;
      position: absolute;
      bottom: 0;
      right: 0;
      img{
        width: 100%;
      }
    }
  }
}

@media (hover: hover) {
  #button_chat_open{
    a:hover{
      opacity: 0.7;
    }
  }
}

@media screen and (max-width: 540px) {

  /* chat_widget
  ====================================================================================================================================== */
  #chat_widget{
    right: 0;
    width: 100%;
  }
  #chat_widget_header{
    .chat_buttons{
      gap: 16px;
    }
  }
  #chat_widget_body{
    height: 400px;
  }

  /* button_chat_open
  ====================================================================================================================================== */
  #button_chat_open{
    bottom: 16px;
    right: 75px;
    a{
      font-size: 0.8em;
      padding: 10px 73px 10px 20px;
      .charactor{
        width: 73px;
      }
    }
  }

}